var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('member-login', {
    attrs: {
      "redirect": "/members",
      "analytics-class": "members_area"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };